<template >
  <section>
    <div v-if="stage == 0">
      <p class="mb-1">目前您的購物車中沒有商品...</p>
      <p>請單擊下面的"繼續購物"</p>
    </div>

    <div v-if="stage == 1">
      <CartItem
        v-for="(item, index) in orderItems"
        :key="item.item_id"
        :value="item"
        :index="index"
        :storeId="storeId"
        :providerId="providerId"
        :readApi="readApi"
        :handleRemoveItem="handleRemoveItem"
        @changeTotal="getChildrenTotal"
      >
      </CartItem>
    </div>

    <div v-if="stage == 2">
      <OrderConfirmCard
        v-for="(item, index) in orderItems"
        :key="index"
        :value="createConfirmCard(item)"
      ></OrderConfirmCard>

      <div class="d-flex mb-5">
        <v-text-field
          class="mx-3"
          :rules="rules"
          name="name"
          hide-details="auto"
          :value="name"
          :label="$t('store.data.name')"
          :placeholder="$t('store.data.name')"
        ></v-text-field>

        <v-text-field
          class="mx-3"
          :rules="rules"
          name="phone"
          hide-details="auto"
          :value="phone"
          :label="$t('store.data.member_phone')"
          :placeholder="$t('store.data.member_phone')"
        ></v-text-field>
      </div>

      <div class="d-flex">
        <v-select
          class="mx-1"
          :rules="rules"
          dense
          outlined
          :items="citiesList"
          name="city"
          :item-value="city"
          :label="$t('data.city')"
          v-model="city"
        ></v-select>

        <v-select
          class="mx-1"
          :rules="rules"
          dense
          outlined
          :items="districtsList"
          name="district"
          :item-value="district"
          :label="$t('data.district')"
          v-model="district"
        ></v-select>
      </div>

      <v-text-field
        class="mx-3"
        :rules="rules"
        name="address"
        hide-details="auto"
        :value="address"
        :label="$t('data-receive-address')"
        :placeholder="$t('data-receive-address')"
      ></v-text-field>

      <v-textarea class="mt-8" name="comment" label="備註" outlined></v-textarea>
    </div>

    <div v-if="freightPriceFree - amount > 0" class="mx-3" style="color: #032F0C">
      只差{{ freightPriceFree - amount }}元即可享有免運優惠!
    </div>

    <number-bar
      v-if="stage != 0"
      readonly
      title="運費"
      unit="元"
      :value="freightPriceFree - amount > 0 ? freightPrice : 0"
      type="secondary"
    ></number-bar>

    <number-bar
      v-if="stage != 0"
      readonly
      title="商品總計"
      unit="元"
      :value="amount"
      type="secondary"
    ></number-bar>

    <number-bar
      v-if="stage != 0"
      readonly
      title="含運總計"
      unit="元"
      :value="freightPriceFree - amount > 0 ? Number(amount) + Number(freightPrice) : Number(amount)"
      type="secondary"
    ></number-bar>
  </section>
</template>

<script lang="babel" type="text/babel">
import ItemMixin from '@/components/form/custom/ItemMixin.js'
import amountTotal from "@/mixins/amountTotal.js"
import CartItem from "./cartItem.vue"
import OrderConfirmCard from "@/modules/base/components/order/orderConfirmCard.vue"
import numberBar from "@/modules/base/components/bar/numberBar.vue"
import discountMixins from "@/mixins/discount.js";
import citiesList from "@/config/citiesList.json"

export default {
    mixins: [ItemMixin, amountTotal, discountMixins],
    props: {
      props: Object
    },
    components: {
      CartItem,
      numberBar,
      OrderConfirmCard
    },
    data: () => ({
      rules: [
        value => !!value || '此欄位為必填',
      ],
      formKey: "info-form",
      data : [],
      me : [],
      freightPriceList : [],
      freightPrice : 0,
      freightPriceFree : 0,
      citiesList: [],
      city: '',
      district: '',
      address: '',
      name: '',
      phone: '',
      outlying_islands: [
        '東沙島',
        '太平島',
        '琉球鄉',
        '蘭嶼鄉',
        '綠島鄉',
        '馬公市',
        '湖西鄉',
        '白沙鄉',
        '西嶼鄉',
        '望安鄉',
        '七美鄉',
        '金城鎮',
        '金寧鄉',
        '金沙鎮',
        '烈嶼鄉',
        '金湖鎮',
        '烏坵鄉',
        '南竿鄉',
        '北竿鄉',
        '莒光鄉',
        '東引鄉',
      ],
    }),
    watch : {
      propsValue: {
        immediate: true,
        deep: true,
        handler() {
            if(!this.propsValue) return ;

            this.data = this.$eagleLodash.cloneDeep(this.propsValue)
            if(!this.data || this.data.length == 0) {
              this.changeStage(0)
            }

        },
      },
      district: {
        immediate: true,
        deep: true,
        handler() {
          if (this.outlying_islands.includes(this.district)) {
            this.freightPriceList.map(item => {
              // 外島運費
              if (item.temperature === 'normal' && item.area === 'outlying_islands') {
                this.freightPrice = item.amount
              }
              // 外島免運門檻
              if (item.temperature === 'normal' && item.area === 'outlying_islands_free') {
                this.freightPriceFree = item.amount
              }
            })
          } else {
            this.freightPriceList.map(item => {
              // 本島運費
              if (item.temperature === 'normal' && item.area === 'main_island') {
                this.freightPrice = item.amount
              }
              // 本島免運門檻
              if (item.temperature === 'normal' && item.area === 'main_island_free') {
                this.freightPriceFree = item.amount
              }
            })
          }
        },
      }
    },
    async mounted() {
      this.citiesList = 
      citiesList.map(item => item.city)

      this.freightPriceList = await this.getFreightPrice();
      this.me = await this.getMe();

      this.name = this.me.name
      this.phone = this.me.phone
      this.city = this.me.city
      this.district = this.me.district
      this.address = this.me.address
    },
    computed: {
      districtsList() {
        if (this.city.length === 0) return
        const targetCity = citiesList.find(item => {
          return item.city === this.city
        })
        return targetCity.districts.map(item => item.name)
      },
      orderItems() {
        const orderItems = this.data.map(item => {
          return {
            ...item,
            price: this.getDiscountPrice({
              discount_config: item.discount_config,
              price: item.product_price,
              quantity: item.quantity
            })
          }
        })
        return orderItems
      },
      storeId() {
        return this.$store.getters['member/storeId']
      },
      providerId() {
        return this.$route.params.providerId
      },
      changeStage() {
        return this.props.changeStage
      },
      stage() {
        if(!this.props) return 1
        return this.props.stage
      },
      readApi() {
        return this.props.readApi
      },
      formData() {
        return this.$store.getters[`form/${this.formKey}/data`];
      },
    },
    methods: {
      async getMe() {
        try {
          const res = await this.$api.collection.storeApi.readByProviderAndStore(this.storeId, this.providerId)
          return res;
        } catch (err) {
          console.err(err);
          return [];
        }
      },
      async getFreightPrice() {
        try {
          const res = await this.$api.collection.providerApi.getFreightPrice(this.providerId);
          return res;
        } catch (err) {
          console.err(err);
          return [];
        }
      },
      async handleRemoveItem(index) {
        await this.$nextTick()
        await this.getChildrenTotal()
      },
      createConfirmCard(item) {
        return {
          product_name: item.product_name,
          shipping_unit: item.shipping_unit,
          calculate_unit: item.calculate_unit,
          per_shipping_count: item.per_shipping_count,
          price: item.price,
          order_count: this.$helper.reverseShippingCount(item.quantity, item.shipping_unit)
        }
      },
    },
}
</script>

